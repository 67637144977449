* {
    font-family: Open Sans, sans-serif;
    margin: 0;
    padding: 0;
}

html {
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    scrollbar-width: none;
}

body{
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}

#root {
    min-width: 100vw;
    min-height: 100vh;
}

.app {
    min-width: 100vw;
    min-height: 100vh;
}